body {
  text-align: center;
  padding: 0;
  margin: 0;
  font-family: Poppins;
  min-height: -webkit-fill-available;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
